import { FC, useCallback, MouseEvent } from "react";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch, dashboard, signings } from "store";
import { setCurrentFolder, setDestinationFolder } from "store/folders";
import { setSearch } from "store/dashboard";
import { setInboxDoc, setSentDoc } from "store/signings";

import {
  ContextMenu,
  RowActions,
  DocImage,
  BreadcrumbsGlobalSearchDoc,
} from "components";
import { Checkbox } from "components/UI";

import { cs, getSearchItems, folderItemName, getSigningType } from "utils";
import { IGlobalSearchDoc, SIGNING_STATUSES, SigningsType } from "types";
import { useTableRow } from "hooks";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

interface IGlobalSearchTableRowProps {
  onChange: () => void;
  handleSetSigningType: (signingsType?: SigningsType) => void;
  item: IGlobalSearchDoc;
}

export const GlobalSearchTableRow: FC<IGlobalSearchTableRowProps> = ({
  onChange,
  handleSetSigningType,
  item,
}) => {
  const {
    type,
    date,
    previewUrl,
    id,
    name,
    parents,
    participantType,
    pagesNumber,
    contentsNumber,
    status,
    isSigningEntity,
    isFileDeleted,
  } = item;
  const { dateFormat, timeFormat, selectedSearchItems } =
    useAppSelector(dashboard);
  const { selectedInboxItems, selectedSentItems } = useAppSelector(signings);
  const { t } = useTranslation("Sidebar");
  const isViewHistory = isFileDeleted && isSigningEntity && !previewUrl;

  const {
    isClicked,
    isHovered,
    isRowMenu,
    points,
    handleRightClick: onRightClick,
    handleOver,
    handleLeave,
    handleSetRowMenu,
    handleClearClicked,
  } = useTableRow();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signingsType = getSigningType({ participantType, status });
  const isSelected = selectedSearchItems.some((el) => el.id === id);
  const isSameEntitiesSelected = [...selectedSearchItems, item].every(
    (item, _, array) =>
      item.isFileDeleted === array[0].isFileDeleted &&
      item.isSigningEntity === array[0].isSigningEntity &&
      item.participantType === array[0].participantType &&
      item.status === array[0].status,
  );

  const handleOpenFolder = useCallback(() => {
    if (isFileDeleted) {
      navigate(PATHES.TRASH);
    } else {
      navigate(PATHES.HOME);
    }
    dispatch(setCurrentFolder(id));
    dispatch(setSearch(""));
  }, [dispatch, id, isFileDeleted, navigate]);

  const handleOpenInboxFile = () => {
    dispatch(setInboxDoc(null));
    localStorage.removeItem("instantJSON");
    id
      ? navigate(`${PATHES.INBOX_DOCUMENT}/${id}`)
      : navigate(`${PATHES.INBOX_DOCUMENT}/${selectedInboxItems[0].id}`);
    dispatch(setSearch(""));
  };

  const handleOpenSentFile = () => {
    dispatch(setSentDoc(null));
    localStorage.removeItem("instantJSON");
    id
      ? navigate(`${PATHES.SENT_DOCUMENT}/${id}`)
      : navigate(`${PATHES.SENT_DOCUMENT}/${selectedSentItems[0].id}`);
    dispatch(setSearch(""));
  };

  const handleRightClick = (e: MouseEvent<HTMLElement>) => {
    onRightClick(e);
    dispatch(setDestinationFolder(null));
  };

  const handleCheck = () => {
    onChange();
  };

  const handleSelectRow = () => {
    onChange();
  };

  const handleOpen = async () => {
    if (type === "FOLDER") {
      handleOpenFolder();
    } else if (type) {
      type === "PDF"
        ? navigate(`${PATHES.DETAILED_VIEW}/${id}`)
        : navigate(`${PATHES.DETAILED_VIEW}/certified/${id}`);
    } else if (signingsType === "inbox") {
      handleOpenInboxFile();
    } else if (signingsType === "sent") {
      handleOpenSentFile();
    }
  };

  const getRootFolderName = () => {
    if (isSigningEntity) {
      return t("signings");
    }
    if (isFileDeleted) {
      return t("trashBin");
    }
    return t("rootFolderName");
  };

  const mainActionItems =
    status !== "NEED_TO_SIGN" &&
    !isViewHistory &&
    getSearchItems({
      selectedItems: selectedSearchItems,
      selectedItemsCount: 1,
      signingsType,
      isSigningEntity,
      isFileDeleted,
      status,
      type,
    });
  const additionalActionItems =
    !isViewHistory &&
    getSearchItems({
      selectedItems: selectedSearchItems,
      selectedItemsCount: 1,
      isAdditional: true,
      signingsType,
      isSigningEntity,
      isFileDeleted,
      status,
      type,
    });
  const contextActionItems = getSearchItems({
    selectedItems: selectedSearchItems,
    selectedItemsCount: 1,
    signingsType,
    isAllShowed: true,
    isSigningEntity,
    isFileDeleted,
    status,
    type,
    isViewHistory,
  });

  return (
    <OutsideClickHandler onOutsideClick={handleClearClicked}>
      <div
        onContextMenu={handleRightClick}
        onMouseEnter={handleOver}
        onMouseLeave={handleLeave}
        onClick={handleSelectRow}
        className={cs([
          styles.GlobalSearchTableRow,
          isSelected && styles.selected,
          type === "PDF_CERTIFIED" && styles.certified,
        ])}
      >
        <div className={styles.td} onClick={(e) => e.stopPropagation()}>
          <Checkbox
            name={id}
            onChange={handleCheck}
            isChecked={isSelected}
            isFakeDisabled={!isSameEntitiesSelected}
          />
        </div>
        <div className={cs([styles.td, styles.black])}>
          <div className={styles.name} onClick={handleOpen}>
            <DocImage type={type} previewUrl={previewUrl} />
            <div className={styles.nameWithDir}>
              {parents && (
                <BreadcrumbsGlobalSearchDoc
                  parents={parents}
                  rootFolderName={getRootFolderName()}
                  isSent={signingsType === "sent"}
                  isInbox={signingsType === "inbox"}
                  isDraft={signingsType === "draft"}
                  isTrash={isFileDeleted}
                  onChange={handleCheck}
                  isViewHistory={isViewHistory}
                />
              )}
              <div className={styles.textName}>{name}</div>
            </div>
          </div>
          <RowActions
            actions={mainActionItems}
            additionalActions={additionalActionItems}
            isRowMenu={isRowMenu}
            points={points}
            handleSetRowMenu={handleSetRowMenu}
            handleLeave={handleLeave}
            id={id}
            isShowed={isHovered}
            isTrash={isFileDeleted}
            signingsStatus={status}
            signingsType={signingsType}
            isViewHistory={isViewHistory}
            onClick={() => handleSetSigningType(signingsType)}
          />
        </div>
        <div className={styles.td}>
          {status ? (
            <div className={cs([styles.status, styles[status]])}>
              {SIGNING_STATUSES[status]}
            </div>
          ) : (
            folderItemName[type]
          )}
        </div>
        <div className={styles.td}>
          {`${pagesNumber || contentsNumber || 0} ${
            type === "FOLDER"
              ? `item${contentsNumber === 1 ? "" : "s"}`
              : `page${pagesNumber === 1 ? "" : "s"}`
          } `}
        </div>
        <div className={styles.td}>
          {dayjs(date).format(`${dateFormat}, ${timeFormat}`)}
        </div>
        {isClicked && (
          <ContextMenu
            theme="light"
            items={contextActionItems}
            top={points.y}
            left={points.x}
            isFixed
            id={id}
            onClose={handleClearClicked}
            signingsType={signingsType}
            signingsStatus={status}
            isViewHistory={isViewHistory}
          />
        )}
      </div>
    </OutsideClickHandler>
  );
};
