import { useEffect, useState, useCallback, FC } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import OutsideClickHandler from "react-outside-click-handler";
import { useParams, useNavigate } from "react-router-dom";

import {
  useAppDispatch,
  useAppSelector,
  folders,
  dashboard,
  signatures,
  subscription,
  signings,
} from "store";
import {
  setOpenedDocument,
  setIsLoading,
  setOpenedDocMetadata,
} from "store/dashboard";
import { setStoredInstance } from "store/signatures";
import { setIsDetailedViewMenu } from "store/signings";
import {
  setSubscriptionRequiredModal,
  setPremiumRequiredModal,
} from "store/subscription";
import {
  setIsDeleteItemsModal,
  setSoftDeletedItems,
  setRenameModalItem,
  setIsMovingItemsModal,
  setDestinationFolder,
  setCurrentContextFolder,
  setCertifyModalItem,
} from "store/folders";
import { getFolder } from "store/folders/thunks";
import { getMetadata } from "store/dashboard/thunks";

import { Button, Modal, Icon } from "components/UI";
import {
  AreYouSureModal,
  RenameModal,
  MovingItemsModal,
  CreateFolderModal,
  CertifyModal,
  ConfirmModal,
  MetadataModal,
} from "components";

import { PDF } from "api";
import {
  useActions,
  useEffectOnce,
  useUploadRequest,
  useSubscription,
} from "hooks";
import { palette } from "utils";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

type DetailedViewHeaderProps = {
  isCertified?: boolean;
};

export const DetailedViewHeader: FC<DetailedViewHeaderProps> = ({
  isCertified,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("DetailedViewHeader");
  const { t: tS } = useTranslation("Sidebar");
  const { openedDocument, dateFormat, timeFormat, openedDocMetadata } =
    useAppSelector(dashboard);
  const { isDetailedViewMenu } = useAppSelector(signings);
  const { storedInstance } = useAppSelector(signatures);
  const { isSubscriptionRequiredModal, isPremiumRequiredModal } =
    useAppSelector(subscription);
  const { id, name, modifiedAt } = openedDocument || {};
  const { handleDelete, handleDeleteSuccess, handleRename } = useActions(id);
  const { handleUpdateFile, getFileFromInstance } = useUploadRequest();
  const { isTrialWasUsed } = useSubscription();
  const {
    isDeleteItemsModal,
    isMovingItemsModal,
    renameModalItem,
    certifyModalItem,
    currentFolder,
    allGeneralItems,
  } = useAppSelector(folders);
  const { documentUserID } = useParams();
  const navigate = useNavigate();
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
  const [isMetadataModal, setIsMetadataModal] = useState<boolean>(false);

  const getDocument = useCallback(async () => {
    if (documentUserID) {
      const res = isCertified
        ? await PDF.getSignedPdfDocument(documentUserID)
        : await PDF.getPdfDocument(documentUserID);
      if (res?.document) {
        const { id, url, name, modifiedAt } = res?.document || {};
        dispatch(setOpenedDocument({ id, url, name, modifiedAt }));
      }
    }
  }, [dispatch, documentUserID, isCertified]);

  useEffectOnce(() => {
    getDocument();
  });

  useEffect(() => {
    if (allGeneralItems.length === 0 && currentFolder) {
      dispatch(getFolder({ id: currentFolder }));
    }
  }, [dispatch, currentFolder, allGeneralItems.length]);

  useEffect(() => {
    if (openedDocMetadata?.documentId) {
      setIsMetadataModal(true);
    }
  }, [openedDocMetadata?.documentId]);

  const handleCloseDocument = async () => {
    if (storedInstance) {
      dispatch(setIsLoading(true));

      const editedPDF =
        openedDocument?.name &&
        (await getFileFromInstance(storedInstance, openedDocument.name));
      editedPDF &&
        documentUserID &&
        (await handleUpdateFile(editedPDF, documentUserID));
      dispatch(setIsLoading(false));
    }
    dispatch(setOpenedDocument(null));
    storedInstance && dispatch(setStoredInstance(null));
    navigate(PATHES.HOME);
  };

  const handleCloseAreYouSureDelete = () => {
    dispatch(setIsDeleteItemsModal(false));
    dispatch(setSoftDeletedItems([]));
  };

  const handleConfirmAreYouSureDelete = async () => {
    dispatch(setIsDeleteItemsModal(false));
    await handleDeleteSuccess(t("deleteSuccessToast"));
    handleCloseDocument();
  };

  const handleOpenMenu = () => {
    dispatch(setIsDetailedViewMenu(true));
    // setIsMenu(true);
  };

  const handleCloseMenu = () => {
    dispatch(setIsDetailedViewMenu(false));
    // setIsMenu(false);
  };

  const handleDeleteDocument = () => {
    if (id) {
      handleDelete();
      handleCloseMenu();
    }
  };

  const handleCloseRenameModal = () => {
    dispatch(setRenameModalItem(""));
  };

  const handleCloseCertifyModal = () => {
    dispatch(setCertifyModalItem(""));
  };

  const handleOpenCreate = () => {
    setIsCreateModal(true);
  };

  const handleCloseMoveModal = () => {
    dispatch(setDestinationFolder(null));
    dispatch(setIsMovingItemsModal(false));
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseCreate = () => {
    setIsCreateModal(false);
    dispatch(setCurrentContextFolder(null));
  };

  const handleOpenMetadataModal = async () => {
    if (documentUserID) {
      dispatch(getMetadata(documentUserID));
    }
  };

  const handleCloseMetadataModal = async () => {
    dispatch(setOpenedDocMetadata(null));
    setIsMetadataModal(false);
  };

  const handleCloseSubscriptionRequired = () => {
    dispatch(setSubscriptionRequiredModal(false));
  };

  const handleSubmitSubscriptionRequired = () => {
    dispatch(setSubscriptionRequiredModal(false));
    navigate(PATHES.SUBSCRIPTION_PLANS);
  };

  const handleClosePremiumRequired = () => {
    dispatch(setPremiumRequiredModal(false));
  };

  const handleSubmitPremiumRequired = () => {
    dispatch(setPremiumRequiredModal(false));
    navigate(PATHES.SUBSCRIPTION_PLANS);
  };

  return (
    <nav className={styles.DetailedViewHeader}>
      <Modal
        isShowed={isDeleteItemsModal}
        onClose={handleCloseAreYouSureDelete}
        className={styles.modal}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureDelete}
          onSubmit={handleConfirmAreYouSureDelete}
          info={t("areYouSureDeleteInfo")}
          confirmButtonTitle={t("areYouSureDeleteConfirmButtonTitle")}
          noCloseOnSubmit
        />
      </Modal>
      <Modal isShowed={!!renameModalItem}>
        <RenameModal onClose={handleCloseRenameModal} isDetailedView />
      </Modal>
      <Modal isShowed={!!certifyModalItem}>
        <CertifyModal onClose={handleCloseCertifyModal} isDetailedView />
      </Modal>
      <Modal isShowed={isMovingItemsModal}>
        <MovingItemsModal
          onClose={handleCloseMoveModal}
          onCreate={handleOpenCreate}
        />
      </Modal>
      <Modal
        styleWrap={{ zIndex: 110 }}
        isShowed={isCreateModal}
        onClose={handleCloseCreate}
      >
        <CreateFolderModal onClose={handleCloseCreate} isMoving />
      </Modal>
      <Modal isShowed={isSubscriptionRequiredModal}>
        <ConfirmModal
          title={tS("titleOnlyWithBasicPremium")}
          info={tS("infoOnlyWithBasicPremium")}
          submitTitle={
            isTrialWasUsed
              ? tS("submitButtonTitle")
              : tS("submitButtonTrialTitle")
          }
          submitAction={handleSubmitSubscriptionRequired}
          cancelTitle={tS("cancelButtonTitle")}
          cancelAction={handleCloseSubscriptionRequired}
        />
      </Modal>
      <Modal isShowed={isPremiumRequiredModal}>
        <ConfirmModal
          title={tS("titleOnlyWithPremium")}
          info={tS("infoOnlyWithPremium")}
          submitTitle={tS("submitButtonTitle")}
          submitAction={handleSubmitPremiumRequired}
          cancelTitle={tS("cancelButtonTitle")}
          cancelAction={handleClosePremiumRequired}
        />
      </Modal>
      <Modal isShowed={isMetadataModal}>
        <MetadataModal onClose={handleCloseMetadataModal} />
      </Modal>
      <div className={styles.inner}>
        <Button
          title={t("backButtonTitle")}
          size="sm"
          variant="textBlack"
          onClick={handleCloseDocument}
          iconStart="chevron-left-empty"
          iconStartType="stroke"
          className={styles.backButton}
        />
        <div className={styles.info}>
          <h2 className={styles.title} onClick={() => handleRename()}>
            {name}{" "}
            <Icon
              name="edit"
              className={styles.editNameIcon}
              color={palette.grey90}
              size={16}
            />
          </h2>
          <p className={styles.date}>
            {t("modifiedOn")}{" "}
            {dayjs(modifiedAt).format(`${dateFormat}, ${timeFormat}`)}
          </p>
        </div>
        <div className={styles.actionButtons}>
          <OutsideClickHandler onOutsideClick={handleCloseMenu}>
            <div onClick={handleOpenMenu} className={styles.actionButton}>
              <Icon name="three-dots-horizontal" className={styles.action} />
              {isDetailedViewMenu && (
                <Button
                  title={t("deleteButtonTitle")}
                  size="md"
                  variant="secondary"
                  onClick={handleDeleteDocument}
                  iconStart="trash"
                  iconStartType="stroke"
                  className={styles.deleteButton}
                />
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
      {isCertified && (
        <div className={styles.certified} onClick={handleOpenMetadataModal}>
          <div className={styles.content}>
            <Icon name="certification" />
            <p>{t("certifiedDocument")}</p>
          </div>
        </div>
      )}
    </nav>
  );
};
