import { FC, useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { useDebouncedCallback } from "use-debounce";
import { isAxiosError } from "axios";
import { useParams } from "react-router-dom";

import { Contacts, SharedDocuments } from "api";
import {
  useAppSelector,
  useAppDispatch,
  requestSigning,
  auth,
  contacts,
} from "store";
import { setRecipients } from "store/requestSigning";
import { getContacts } from "store/contacts/thunks";

import { Input, Button, Spinner } from "components/UI";
import { palette, toastError, validateEmail } from "utils";
import { IRecipient, IContact } from "types";
import { useEffectOnce } from "hooks";

import { ModalHeader } from "../ModalHeader";

import styles from "./styles.module.scss";

type AddRecipientModalProps = {
  onClose: () => void;
  recipient: IRecipient | null;
  recipientIndex?: number;
};

export const AddRecipientModal: FC<AddRecipientModalProps> = ({
  onClose,
  recipient,
  recipientIndex = 0,
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(auth);
  const { recipients } = useAppSelector(requestSigning);
  const { allContacts } = useAppSelector(contacts);
  const { t } = useTranslation("Modals", { keyPrefix: "AddRecipient" });
  const { t: tG } = useTranslation("General");
  const { draftId } = useParams();
  const [contactsList, setContactsList] = useState<IContact[]>([]);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [id, setId] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [isLoading] = useState<boolean>(false);
  const [suggestionsFor, setSuggestionsFor] = useState<string>("");

  const handleSearchSuggestions = useDebouncedCallback(
    async (value: string) => {
      if (value) {
        try {
          const res = await Contacts.searchContact({ query: value });
          if (res?.items) {
            setContactsList(
              res.items.filter(
                (item) => !recipients.some((el) => el.email === item.email),
              ),
            );
          }
        } catch (error) {
          console.log("error:", error);
          if (isAxiosError(error)) {
            error?.message &&
              toastError(
                Array.isArray(error.message) ? error.message[0] : error.message,
              );
          }
        }
      }
    },
    500,
  );

  useEffectOnce(() => {
    if (allContacts.length === 0) {
      dispatch(getContacts());
    }
  });

  useEffect(() => {
    if (recipient) {
      setEmail(recipient.email);
      setName(recipient.name);
    }
  }, [recipient]);

  const checkEmailValidity = () => {
    const emailValidationErrors = email && validateEmail(email);

    if (emailValidationErrors.length > 0) {
      setEmailError(emailValidationErrors[0]);
    }
    return emailValidationErrors;
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 80) return;
    setName(e.target.value);
    handleSearchSuggestions(e.target.value);
    setSuggestionsFor(`name${recipientIndex}`);
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailError("");
    if (recipients.some((el) => el.email === e.target.value)) {
      setEmailError(t("emailRecipientError"));
    } else if (e.target.value === user?.email) {
      setEmailError(t("emailOwnerError"));
    } else {
      setEmail(e.target.value);
      handleSearchSuggestions(e.target.value);
      setSuggestionsFor(`email${recipientIndex}`);
    }
  };

  const handleSubmit = async () => {
    if (checkEmailValidity().length > 0) return;

    let res = [...recipients];
    if (recipient) {
      res[recipientIndex] = { ...res[recipientIndex], name, email };
    } else {
      res.push({ id, name, email, error: "" });
      res = res.slice().sort((a, b) => a.name.localeCompare(b.name));
    }
    dispatch(setRecipients(res));
    if (draftId) {
      await SharedDocuments.updateDraft(draftId, {
        recipients: res.map(({ name, email }) => ({ name, email })),
      });
    }

    onClose();
  };

  const handleClearName = () => {
    setName("");
  };

  const handleClearEmail = () => {
    setEmail("");
    setEmailError("");
  };

  const handleRemoveRecipient = async () => {
    const res = [...recipients];
    const savedInstantJSON = localStorage.getItem("instantJSON");
    const savedInstantJSONParsed = savedInstantJSON
      ? JSON.parse(savedInstantJSON)
      : null;
    const filteredAnnotations = savedInstantJSONParsed?.annotations?.filter(
      (item: any) => item.recipient !== recipients[recipientIndex]?.email,
    );
    const filteredFormFields = savedInstantJSONParsed?.formFields?.filter(
      (item: any) => item.recipient !== recipients[recipientIndex]?.email,
    );
    const filteredFormFieldValues =
      savedInstantJSONParsed?.formFieldValues?.filter(
        (item: any) => item.recipient !== recipients[recipientIndex]?.email,
      );
    const dataToSave = {
      ...savedInstantJSONParsed,
      annotations: filteredAnnotations,
      formFields: filteredFormFields,
      formFieldValues: filteredFormFieldValues,
    };
    const isAnyData =
      filteredAnnotations?.length > 0 ||
      filteredFormFields > 0 ||
      filteredFormFieldValues > 0;
    const dataStringified = isAnyData ? JSON.stringify(dataToSave) : "null";

    localStorage.setItem("instantJSON", dataStringified);

    res.splice(recipientIndex, 1);
    dispatch(setRecipients(res || []));

    if (draftId) {
      await SharedDocuments.updateDraft(draftId, {
        recipients: res.map(({ name, email }) => ({ name, email })),
      });
    }
    onClose();
  };

  const handleCloseSuggestions = () => {
    setContactsList([]);
    setSuggestionsFor("");
  };

  const handleSelectContact = ({ id, name, email }: IContact) => {
    handleCloseSuggestions();
    setEmail(email);
    setEmailError("");
    setName(name);
    setId(id);
  };

  const renderSuggestions = () => {
    if (contactsList.length > 0) {
      return (
        <OutsideClickHandler onOutsideClick={handleCloseSuggestions}>
          <div className={styles.suggestions}>
            {contactsList.map((item) => (
              <div
                key={item.id}
                className={styles.contact}
                onClick={() => handleSelectContact(item)}
              >
                <div className={styles.name}>{item.name}</div>
                <div className={styles.email}>{item.email}</div>
              </div>
            ))}
          </div>
        </OutsideClickHandler>
      );
    }
  };

  const submitButtonTitle = recipient
    ? t("submitEditButtonTitle")
    : t("submitAddButtonTitle");

  return (
    <div className={styles.AddRecipientModal}>
      <ModalHeader
        onClose={onClose}
        title={recipient ? t("titleEdit") : t("titleAdd")}
      />
      <div className={styles.inputWrap}>
        <Input
          className={styles.nameInput}
          onChange={handleChangeName}
          value={name}
          name="name"
          isClearButton
          onClear={handleClearName}
          placeholder={t("namePlaceholder")}
          label={t("nameLabel")}
          autoComplete="off"
          isRequired
        />
        {suggestionsFor === `name${recipientIndex}` && renderSuggestions()}
      </div>
      <div className={styles.inputWrap}>
        <Input
          onChange={handleChangeEmail}
          onBlur={checkEmailValidity}
          value={email}
          error={emailError}
          name="email"
          isClearButton
          onClear={handleClearEmail}
          placeholder={tG("emailPlaceholder")}
          label={tG("emailLabel")}
          autoComplete="off"
          isRequired
        />
        {suggestionsFor === `email${recipientIndex}` && renderSuggestions()}
      </div>
      <div className={styles.buttons}>
        <Button
          variant="primary"
          title={
            isLoading ? <Spinner color={palette.white} /> : submitButtonTitle
          }
          onClick={handleSubmit}
          isDisabled={!name || !email || !!emailError}
        />
        {recipient && (
          <Button
            variant="secondary"
            title={t("cancelEditButtonTitle")}
            onClick={handleRemoveRecipient}
            className={styles.deleteButton}
          />
        )}
      </div>
    </div>
  );
};
