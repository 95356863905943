import { useCallback } from "react";
import { Annotation, Instance } from "pspdfkit";
import axios from "axios";

import { Signatures } from "api";
import {
  useAppDispatch,
  useAppSelector,
  requestSigning,
  signatures,
  settings,
} from "store";
import { setAttachmentsArray, setIsCreated } from "store/signatures";
import { deleteSignature as thunkDeleteSignature } from "store/signatures/thunks";

import { toBase64, pspdfkitColorsList, sleep } from "utils";

type SaveAnnotationAtachmentParams = {
  annotation: Annotation;
  instance: Instance;
  isInitial?: boolean;
};

type SaveSignaturesParams = {
  annotation: Annotation;
  instance: Instance;
  PSPDFKit: any;
  isInitial?: boolean;
  isGuest?: boolean;
};

type DeleteSignatureParams = {
  annotation: Annotation;
  instance: Instance;
  PSPDFKit: any;
  isGuest?: boolean;
  userId?: string;
};

type GetDynamicColorParams = {
  PSPDFKit: any;
};

type SaveInstanceJSONParams = {
  // isAuto?: boolean;
  instance: Instance;
  recipientId: string;
};

type SignAllParams = {
  instance: Instance;
  PSPDFKit: any;
};

type CleanAllParams = {
  instance: Instance;
};

type SignSignatureFieldParams = {
  annotation: Annotation;
  instance: Instance;
  PSPDFKit: any;
};

type GetUnsignedWidgetsParams = {
  instance: Instance;
  PSPDFKit: any;
};

type GetSignedWidgetsParams = {
  instance: Instance;
};

export const useInstance = () => {
  const dispatch = useAppDispatch();
  const { editedDraft, activeRecipient, recipients } =
    useAppSelector(requestSigning);
  const { allSignatures } = useAppSelector(signatures);
  const { user } = useAppSelector(settings);

  const ATTACHMENTS_KEY = "attachments_storage";
  const STORAGE_KEY = "signatures_storage";
  const signaturesString = localStorage.getItem(STORAGE_KEY);
  const storedSignatures = signaturesString ? JSON.parse(signaturesString) : [];

  // const getInkAnnotationImage = useCallback(
  //   async ({ annotation, instance }: SaveAnnotationAtachmentParams) => {
  //     const page = instance.contentDocument.querySelector(".PSPDFKit-Page");
  //     const svgSelector =
  //       `.PSPDFKit-Ink-Annotation[data-annotation-id="${annotation.id}"] svg` as any;
  //     const observer = new MutationObserver(async () => {
  //       const svgEl = instance.contentDocument.querySelector(svgSelector);
  //       if (svgEl) {
  //         observer.disconnect();
  //         svgEl.querySelector("g").style.fill = "transparent";
  //         const svg = new XMLSerializer().serializeToString(svgEl);
  //         const img = new Image();
  //         img.src = `data:image/svg+xml; charset=utf-8, ${svg}`;
  //         img.onload = () => {
  //           const canvas = document.createElement("canvas");
  //           canvas.width = img.naturalWidth;
  //           canvas.height = img.naturalHeight;
  //           const ctx = canvas.getContext("2d");
  //           if (ctx) {
  //             ctx.drawImage(img, 0, 0);
  //             canvas.toBlob(async (blob) => {
  //               const file =
  //                 blob &&
  //                 new File([blob], `image.png`, {
  //                   type: "image/png",
  //                 });
  //               const url = file && (await toBase64(file));
  //               dispatch(setAttachmentUrl(url as string));
  //             }, "image/png");
  //           }
  //         };
  //       }
  //     });
  //     page && observer.observe(page, { subtree: true, childList: true });
  //   },
  //   [dispatch],
  // );

  const saveInkAnnotationAtachment = useCallback(
    ({ annotation, instance, isInitial }: SaveAnnotationAtachmentParams) => {
      const page = instance.contentDocument.querySelector(".PSPDFKit-Page");
      const svgSelector =
        `.PSPDFKit-Ink-Annotation[data-annotation-id="${annotation.id}"] svg` as any;
      const observer = new MutationObserver(async () => {
        const svgEl = instance.contentDocument.querySelector(svgSelector);
        if (svgEl) {
          observer.disconnect();
          svgEl.querySelector("g").style.fill = "transparent";
          const svg = new XMLSerializer().serializeToString(svgEl);
          const img = new Image();
          img.src = `data:image/svg+xml; charset=utf-8, ${svg}`;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.naturalWidth;
            canvas.height = img.naturalHeight;
            const ctx = canvas.getContext("2d");
            if (ctx) {
              ctx.drawImage(img, 0, 0);
              canvas.toBlob(async (blob) => {
                const file =
                  blob &&
                  new File([blob], `image.png`, {
                    type: "image/png",
                  });
                const url = file && (await toBase64(file));
                const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);
                let attachmentsArray = attachmentsString
                  ? JSON.parse(attachmentsString)
                  : [];
                // if (attachmentsArray.length <= storedSignatures.length) {
                attachmentsArray = [
                  ...attachmentsArray.filter(
                    (el: any) => el.userId !== user?.id,
                  ),
                  {
                    url,
                    id: annotation.name,
                    userId: user?.id,
                  },
                ];
                // }
                !isInitial &&
                  localStorage.setItem(
                    ATTACHMENTS_KEY,
                    JSON.stringify(attachmentsArray),
                  );
                // dispatch(setAttachmentsArray(attachmentsArray));
              }, "image/png");
            }
          };
        }
      });
      page && observer.observe(page, { subtree: true, childList: true });
    },
    [user],
  );

  const saveImageAnnotationAtachment = useCallback(
    async ({ annotation, instance }: SaveAnnotationAtachmentParams) => {
      const attachment = await instance.getAttachment(
        annotation.imageAttachmentId,
      );
      const file = new File([attachment], `image.png`, {
        type: "image/png",
      });
      const url = await toBase64(file);

      const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);
      const attachmentsArray = attachmentsString
        ? JSON.parse(attachmentsString)
        : [];
      attachmentsArray.push({
        url,
        id: annotation.imageAttachmentId,
        userId: user?.id,
      });
      localStorage.setItem(ATTACHMENTS_KEY, JSON.stringify(attachmentsArray));
      dispatch(setAttachmentsArray(attachmentsArray));
    },
    [dispatch, user],
  );

  const saveInstanceJSON = useCallback(
    ({ instance, recipientId }: SaveInstanceJSONParams) => {
      instance.exportInstantJSON().then((instantJSON: any) => {
        const prevAllData = localStorage.getItem("instantJSON");
        const prevAllDataParsed = prevAllData ? JSON.parse(prevAllData) : {};
        let dataToSave = null;
        const attachments: any = {};

        // const attachments = {
        //   ...(prevAllDataParsed && prevAllDataParsed.attachments),
        // };
        instantJSON.annotations?.forEach((item: any) => {
          // if (!Object.keys(attachments).includes(item.imageAttachmentId)) {
          const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);
          const attachmentsArray =
            attachmentsString && JSON.parse(attachmentsString);
          const attachmentFileURL = attachmentsArray?.find(
            (el: any) => el.id === item.imageAttachmentId,
          )?.url;
          const attachmentBinary =
            attachmentFileURL &&
            attachmentFileURL.slice(attachmentFileURL.indexOf(",") + 1);
          item.imageAttachmentId &&
            (attachments[item.imageAttachmentId] = {
              binary: attachmentBinary,
              contentType: "image/png",
              recipient:
                // (Array.isArray(prevAllDataParsed?.attachments) &&
                //   prevAllDataParsed?.attachments?.find(
                //     (el: any) => el.id === item.id,
                //   )?.recipient) ||
                recipientId,
            });
          // }
        });

        dataToSave = {
          ...instantJSON,
          annotations: instantJSON.annotations
            ? [
                ...instantJSON.annotations.map((item: any) => ({
                  ...item,
                  recipient: item.isSignature
                    ? null
                    : prevAllDataParsed?.annotations?.find(
                        (el: any) => el.id === item.id,
                      )?.recipient || recipientId,
                  owner: item.isSignature,
                  // type: "pspdfkit/doesnotexist",
                })),
              ]
            : [],
          formFields: instantJSON?.formFields
            ? instantJSON.formFields?.map((item: any) => ({
                ...item,
                flags:
                  item.type !== "pspdfkit/form-field/signature"
                    ? ["readOnly"]
                    : [],
                recipient:
                  prevAllDataParsed?.formFields?.find(
                    (el: any) => el.name === item.name,
                  )?.recipient || recipientId,
              }))
            : [],
          formFieldValues: instantJSON?.formFieldValues
            ? instantJSON.formFieldValues.map((item: any) => ({
                ...item,
                recipient:
                  prevAllDataParsed?.formFieldValues?.find(
                    (el: any) => el.name === item.name,
                  )?.recipient || recipientId,
              }))
            : [],
          pdfId: null,
          // attachments,
        };

        const dataStringified = dataToSave && JSON.stringify(dataToSave);
        localStorage.setItem("instantJSON", dataStringified);
      });
    },
    [],
  );

  const saveSignatures = useCallback(
    async ({
      annotation,
      instance,
      PSPDFKit,
      isInitial,
      isGuest,
    }: SaveSignaturesParams) => {
      // const signaturesString = localStorage.getItem(STORAGE_KEY);
      // const storedSignatures = signaturesString
      //   ? JSON.parse(signaturesString)
      //   : [];
      let serializedAnnotation =
        PSPDFKit.Annotations.toSerializableObject(annotation);

      if (annotation.imageAttachmentId) {
        const attachment = await instance.getAttachment(
          annotation.imageAttachmentId,
        );
        const file = new File([attachment], `image.png`, {
          type: "image/png",
        });
        const url = await toBase64(file);
        serializedAnnotation = {
          ...serializedAnnotation,
          customData: {
            atachment: { id: annotation.imageAttachmentId, url },
            isInitial: !!isInitial,
          },
        };
      }

      // if (annotation.lines) {
      //   await getInkAnnotationImage({
      //     annotation,
      //     instance,
      //   });
      //   await sleep(2000);
      //   console.log("attachmentUrl:", attachmentUrl);
      //   if (attachmentUrl) {
      //     serializedAnnotation = {
      //       ...serializedAnnotation,
      //       customData: {
      //         atachment: {
      //           id: annotation.id,
      //           url: attachmentUrl,
      //         },
      //       },
      //     };
      //   }
      // }

      const jsonFile = new Blob([JSON.stringify(serializedAnnotation)], {
        type: "application/json",
      });
      const res =
        !isGuest &&
        (await Signatures.createSignature({
          isDefault: !isInitial,
          file: {
            fileSize: jsonFile.size,
          },
        }));

      const { fileUpload } = res || {};

      if (fileUpload?.url) {
        try {
          const s3Res = await axios.put(fileUpload?.url, jsonFile, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (s3Res.status === 200) {
            console.log("signature upload s3 sucess:", s3Res);
          }
        } catch (error) {
          console.log("signature upload s3 error:", error);
        }
      }

      // storedSignatures.push({
      //   ...serializedAnnotation,
      //   id: dayjs().format("DD-MMM-YYYY_hh:mm:ss"),
      // });
      // localStorage.setItem(STORAGE_KEY, JSON.stringify(storedSignatures));
      await instance.setStoredSignatures((signatures: any) =>
        signatures.push(annotation),
      );
      await sleep(1000);
      dispatch(setIsCreated(true));
    },
    [dispatch],
  );

  const deleteSignature = useCallback(
    async ({
      annotation,
      instance,
      PSPDFKit,
      isGuest,
      userId,
    }: DeleteSignatureParams) => {
      const signaturesString = localStorage.getItem(STORAGE_KEY);
      const storedSignatures = signaturesString
        ? JSON.parse(signaturesString)
        : [];
      const annotations = storedSignatures?.map(
        PSPDFKit.Annotations.fromSerializableObject,
      );
      const updatedAnnotations = annotations.filter(
        (currentAnnotation: any) =>
          // currentAnnotation.name !== annotation.name,
          !currentAnnotation.equals(annotation),
      );
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify(
          updatedAnnotations.map(PSPDFKit.Annotations.toSerializableObject),
        ),
      );
      await instance.setStoredSignatures((signatures: any) =>
        signatures.filter((signature: any) => !signature.equals(annotation)),
      );

      // if (annotation.imageAttachmentId) {
      const attachmentsString = localStorage.getItem(ATTACHMENTS_KEY);
      if (attachmentsString) {
        let attachmentsArray = JSON.parse(attachmentsString);
        attachmentsArray = attachmentsArray.filter(
          (attachment: any) => attachment.userId !== userId,
          // attachment.id !== annotation.imageAttachmentId &&
          // attachment.id !== annotation.name,
        );
        localStorage.setItem(ATTACHMENTS_KEY, JSON.stringify(attachmentsArray));
      }

      if (annotation?.customData?.savedSignatureId && !isGuest) {
        // await Signatures.deleteSignature(
        //   annotation.customData.savedSignatureId as string,
        // );
        dispatch(
          thunkDeleteSignature(
            annotation.customData.savedSignatureId as string,
          ),
        );
      }
    },
    [dispatch],
  );

  const getSavedJSON = useCallback(() => {
    const savedInstantJSON = localStorage.getItem("instantJSON");
    const savedInstantJSONParsed = savedInstantJSON
      ? JSON.parse(savedInstantJSON)
      : null;

    return savedInstantJSONParsed
      ? {
          ...savedInstantJSONParsed,
          ...(savedInstantJSONParsed.annotations?.length > 0 && {
            annotations: savedInstantJSONParsed.annotations,
          }),
          ...(savedInstantJSONParsed.formFields?.length > 0 && {
            formFields: savedInstantJSONParsed.formFields,
          }),
          ...(savedInstantJSONParsed.formFieldValues?.length > 0 && {
            formFieldValues: savedInstantJSONParsed.formFieldValues,
          }),
          pdfId: null,
        }
      : null;
  }, []);

  const getDynamicColor = useCallback(
    ({ PSPDFKit }: GetDynamicColorParams) => {
      let newColor;
      if (editedDraft) {
        const savedJSON = getSavedJSON();
        const recipientAnnotation = savedJSON?.annotations?.find(
          (el: any) => el.recipient === activeRecipient?.email,
        );
        const activeRecipientColor = recipientAnnotation?.backgroundColor;

        const dynamicColor = pspdfkitColorsList.find(
          (el) => el.standart === activeRecipientColor,
        )?.pdf;

        const filteredColors = pspdfkitColorsList.filter(
          (el) =>
            !savedJSON?.annotations?.some(
              (item: any) => item.backgroundColor === el.standart,
            ),
        );
        const diff = pspdfkitColorsList.length - filteredColors.length;
        const dynamicNewColor =
          filteredColors[
            Number(
              recipients?.findIndex((el) => el.name === activeRecipient?.name),
            ) - diff
          ]?.pdf ||
          filteredColors[
            Number(
              recipients?.findIndex((el) => el.name === activeRecipient?.name),
            )
          ]?.pdf;

        newColor = dynamicColor || dynamicNewColor;
      }
      return newColor
        ? new PSPDFKit.Color(newColor)
        : PSPDFKit.Color.TRANSPARENT;
    },
    [activeRecipient, editedDraft, getSavedJSON, recipients],
  );

  const fitIn = useCallback((size: any, containerSize: any) => {
    const { width, height } = size;
    const widthRatio = containerSize.width / width;
    const heightRatio = containerSize.height / height;
    const ratio = Math.min(widthRatio, heightRatio);

    return {
      width: width * ratio,
      height: height * ratio,
    };
  }, []);

  const getStoredSignatures = useCallback(async () => {
    let res;
    if (allSignatures && allSignatures.length > 0) {
      res = await Promise.all(
        allSignatures.map(async (item) => {
          try {
            const signature = await axios.get(item.url);
            return {
              ...signature.data,
              customData: {
                ...signature.data?.customData,
                savedSignatureId: item.id,
                isSignatureDefault: item.isDefault,
              },
            };
          } catch (error) {
            console.log("error:", error);
          }
        }),
      );
    }
    return res;
  }, [allSignatures]);

  const getTotalWidgetsCount = useCallback(
    async ({ instance }: GetUnsignedWidgetsParams) => {
      const totalPages = instance?.totalPageCount;
      const allAnnotations: any[] = [];

      await Promise.all(
        Array.from(Array(totalPages).keys()).map(async (page) => {
          await instance.getAnnotations(page).then(async (annotations) => {
            const widgets = await Promise.all(
              annotations.filter((annotation) => annotation.formFieldName),
            );
            const filteredWidgets = widgets.filter(Boolean);

            filteredWidgets.forEach((annotation) =>
              allAnnotations.push(annotation),
            );
          });
        }),
      );
      return allAnnotations.length;
    },
    [],
  );

  const getUnsignedWidgets = useCallback(
    async ({ instance }: GetUnsignedWidgetsParams) => {
      const totalPages = instance?.totalPageCount;
      const allAnnotations: any[] = [];

      await Promise.all(
        Array.from(Array(totalPages).keys()).map(async (page) => {
          await instance.getAnnotations(page).then(async (annotations) => {
            const widgets = await Promise.all(
              annotations
                .filter((annotation) => annotation.formFieldName)
                .map(async (an) => {
                  const isOverLapped =
                    await instance.getOverlappingAnnotations(an);
                  const isOverLappedField = isOverLapped.size > 0;
                  return isOverLappedField ? null : an;
                }),
            );
            const filteredWidgets = widgets.filter(Boolean);

            filteredWidgets.forEach((annotation) =>
              allAnnotations.push(annotation),
            );
          });
        }),
      );
      return allAnnotations;
    },
    [],
  );

  const getSignedWidgets = useCallback(
    async ({ instance }: GetSignedWidgetsParams) => {
      let counter = 0;
      const formFields = await instance.getFormFields();
      const allFormFieldValues = instance.getFormFieldValues();

      const dateValuesCount = Object?.entries(allFormFieldValues)
        ?.filter((el: any) => el[0].startsWith("DATE_WIDGET"))
        .filter((el) => el[1] && el[1][0] !== "")?.length;
      const checkboxValuesCount = Object?.entries(allFormFieldValues)
        ?.filter((el: any) => el[0].startsWith("CHECKBOX_WIDGET"))
        .filter((el) => el[1] && el[1][0] !== "Off")?.length;
      const textValuesCount = Object?.entries(allFormFieldValues)
        ?.filter((el: any) => el[0].startsWith("TEXT_WIDGET"))
        .filter((el) => el[1] && el[1][0] !== "")?.length;

      const res = await Promise.all(
        formFields.map(async (field) => {
          const isOverLapped = await instance.getOverlappingAnnotations(field);
          counter += isOverLapped.size;
        }),
      );

      if (res) {
        return (
          counter + dateValuesCount + checkboxValuesCount + textValuesCount
        );
      }
    },
    [],
  );

  const createSignatures = useCallback(
    async ({ widgets, PSPDFKit, instance }: any) => {
      // const signaturesString = localStorage.getItem(STORAGE_KEY);
      // const storedSignatures = signaturesString
      // ? JSON.parse(signaturesString)
      // : [];
      const storedSignatures = await getStoredSignatures();
      const annotations = storedSignatures?.map(
        PSPDFKit.Annotations.fromSerializableObject,
      ) as Annotation[];
      const defaultSignature = annotations.find(
        (el) => el?.customData?.isSignatureDefault,
      );

      widgets?.forEach((widget: any) => {
        if (
          storedSignatures &&
          widget &&
          widget.formFieldName?.startsWith("SIGNATURE") &&
          defaultSignature
        ) {
          const newSize = fitIn(
            {
              width: defaultSignature.boundingBox.width,
              height: defaultSignature.boundingBox.height,
            },
            {
              width: widget.boundingBox.width,
              height: widget.boundingBox.height,
            },
          );
          const newLeft =
            widget.boundingBox.left +
            widget.boundingBox.width / 2 -
            newSize.width / 2;
          const newTop =
            widget.boundingBox.top +
            widget.boundingBox.height / 2 -
            newSize.height / 2;
          const resizeRatio =
            newSize.width / defaultSignature.boundingBox.width;
          const newLines = defaultSignature.lines?.map((line: any) => {
            return line.map((point: any) => {
              return new PSPDFKit.Geometry.DrawingPoint({
                x:
                  newLeft +
                  (point.x - defaultSignature.boundingBox.left) * resizeRatio,
                y:
                  newTop +
                  (point.y - defaultSignature.boundingBox.top) * resizeRatio,
              });
            });
          });
          const newBoundingBox = new PSPDFKit.Geometry.Rect({
            left: newLeft,
            top: newTop,
            width: newSize.width,
            height: newSize.height,
          });
          let annotation;
          if (defaultSignature.imageAttachmentId) {
            annotation = new PSPDFKit.Annotations.ImageAnnotation({
              pageIndex: widget.pageIndex,
              boundingBox: newBoundingBox, // widget.boundingBox
              isSignature: true,
              contentType: defaultSignature?.contentType,
              imageAttachmentId: defaultSignature?.imageAttachmentId,
            });
          } else if (
            defaultSignature instanceof PSPDFKit.Annotations.InkAnnotation
          ) {
            annotation = new PSPDFKit.Annotations.InkAnnotation({
              pageIndex: widget.pageIndex,
              boundingBox: newBoundingBox,
              isSignature: true,
              contentType: defaultSignature?.contentType,
              lines: newLines,
              lineWidth: defaultSignature.lineWidth * resizeRatio,
              strokeColor: defaultSignature.strokeColor,
            });
          }
          annotation && instance.create(annotation);
        }
      });
    },
    [fitIn, getStoredSignatures],
  );

  const signSignatureField = useCallback(
    async ({ instance, PSPDFKit, annotation }: SignSignatureFieldParams) => {
      const annotationsToSign = await instance.getAnnotations(
        annotation.pageIndex,
      );
      const signaturesBoxes: any[] = [];
      annotationsToSign.forEach((el) => {
        if (el.isSignature) {
          signaturesBoxes.push({
            right: el.boundingBox?.right,
            left: el.boundingBox?.left,
            top: el.boundingBox?.top,
            bottom: el.boundingBox?.bottom,
          });
        }
      });

      const formFields = await instance.getFormFields();
      const field = formFields.find(
        (formField) =>
          formField.name === annotation.formFieldName &&
          formField instanceof PSPDFKit.FormFields.SignatureFormField,
      );
      if (field) {
        const isOverLapped = await instance.getOverlappingAnnotations(field);
        if (isOverLapped.size > 0) return;
      }
      const widgets = annotationsToSign.filter(
        (an) =>
          an instanceof PSPDFKit.Annotations.WidgetAnnotation &&
          an.id === annotation.id,
      );

      createSignatures({ widgets, PSPDFKit, instance });
    },
    [createSignatures],
  );

  const signAllSignatureFields = useCallback(
    async ({ instance, PSPDFKit }: SignAllParams) => {
      const totalPages = instance?.totalPageCount;
      const formFields = await instance.getFormFields();
      Array.from(Array(totalPages).keys()).forEach(async (page) => {
        const annotationsToSign = await instance.getAnnotations(page);
        const signaturesBoxes: any[] = [];
        annotationsToSign.forEach((el) => {
          if (el.isSignature) {
            signaturesBoxes.push({
              right: el.boundingBox?.right,
              left: el.boundingBox?.left,
              top: el.boundingBox?.top,
              bottom: el.boundingBox?.bottom,
            });
          }
        });

        const widgets = await Promise.all(
          annotationsToSign
            .filter(
              (annotation) =>
                annotation.formFieldName?.startsWith("SIGNATURE_WIDGET"),
            )
            .filter((el) => !el?.customData?.isInitial)
            .map(async (an) => {
              const field = formFields.find(
                (formField) =>
                  formField.name === an.formFieldName &&
                  formField instanceof PSPDFKit.FormFields.SignatureFormField,
              );
              if (field) {
                const isOverLapped =
                  await instance.getOverlappingAnnotations(field);
                const isOverLappedField = isOverLapped.size > 0;
                return isOverLappedField ? null : an;
              }
              return null;
            }),
        );

        const filteredWidgets = widgets.filter(Boolean);

        if (filteredWidgets.length > 0) {
          await createSignatures({
            widgets: filteredWidgets,
            PSPDFKit,
            instance,
          });
        }
      });
    },
    [createSignatures],
  );

  const cleanAllSignatureFields = useCallback(
    async ({ instance }: CleanAllParams) => {
      const totalPages = instance?.totalPageCount;

      if (instance) {
        Array.from(Array(totalPages).keys()).forEach(async (el) => {
          const annotations = await instance.getAnnotations(el);
          annotations
            .filter((el) => !el?.customData?.isInitial)
            .forEach(async (annotation) => {
              if (annotation.isSignature) {
                await instance.delete(annotation.id);
              }
            });
        });
      }
    },
    [],
  );

  const getStoredAtachments = useCallback(async () => {
    const storedSignatures = await getStoredSignatures();
    let attachmentsArray = [];
    if (storedSignatures && storedSignatures?.length > 0) {
      attachmentsArray =
        storedSignatures
          ?.filter((el) => el?.customData)
          .map((el) => {
            return {
              ...el?.customData?.atachment,
              isDefault: el?.customData?.isSignatureDefault,
            };
          }) || [];
    }
    return attachmentsArray;
  }, [getStoredSignatures]);

  return {
    saveInkAnnotationAtachment,
    saveImageAnnotationAtachment,
    saveInstanceJSON,
    saveSignatures,
    deleteSignature,
    getDynamicColor,
    getSavedJSON,
    signAllSignatureFields,
    cleanAllSignatureFields,
    storedSignatures,
    signSignatureField,
    getStoredSignatures,
    getStoredAtachments,
    getUnsignedWidgets,
    getSignedWidgets,
    getTotalWidgetsCount,
  };
};
