import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISignature } from "types";

import { getSignatures, deleteSignature } from "./thunks";

type Attachment = {
  id: string;
  url: string;
};

export type SignaturesState = {
  allSignatures: ISignature[];
  storedInstance: any;
  attachmentsArray: Attachment[];
  isDeleted: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  attachmentUrl: string;
};

const initialState: SignaturesState = {
  allSignatures: [],
  storedInstance: null,
  attachmentsArray: [],
  isDeleted: false,
  isCreated: false,
  isUpdated: false,
  attachmentUrl: "",
};

const signaturesSlice = createSlice({
  name: "signatures",
  initialState,
  reducers: {
    setStoredInstance(state, { payload }: PayloadAction<any>) {
      state.storedInstance = payload;
    },
    setAttachmentsArray(state, { payload }: PayloadAction<Attachment[]>) {
      state.attachmentsArray = payload;
    },
    setIsCreated(state, { payload }: PayloadAction<boolean>) {
      state.isCreated = true;
    },
    setIsUpdated(state, { payload }: PayloadAction<boolean>) {
      state.isUpdated = true;
    },
    setAttachmentUrl(state, { payload }: PayloadAction<string>) {
      state.attachmentUrl = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSignatures.fulfilled, (state, action) => {
      state.allSignatures = action.payload?.items || [];
      state.isDeleted = false;
      state.isCreated = false;
      state.isUpdated = false;
    });
    builder.addCase(deleteSignature.fulfilled, (state, action) => {
      state.isDeleted = !state.isDeleted;
    });
  },
});

export const {
  setStoredInstance,
  setAttachmentsArray,
  setIsCreated,
  setIsUpdated,
  setAttachmentUrl,
} = signaturesSlice.actions;

export default signaturesSlice.reducer;
