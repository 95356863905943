import { FC, useEffect, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  folders,
  dashboard,
  trashBin,
} from "store";
import { setSelectedSearchItems } from "store/dashboard";
import { getFolder } from "store/folders/thunks";

import { NoTableData } from "components";
import { Checkbox, Icon } from "components/UI";
import {
  cs,
  getSearchItems,
  getMenuTitle,
  toastInfo,
  getSigningType,
} from "utils";
import { IGlobalSearchDoc, SigningsType } from "types";
import { useActions } from "hooks";

import { HeaderCell } from "../HeaderCell";
import { GlobalSearchTableRow } from "./Row";
import { GlobalSearchModals } from "./Modals";
import styles from "./styles.module.scss";

export const GlobalSearchTable: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("Table", {
    keyPrefix: "GlobalSearch",
  });
  const { t: tG } = useTranslation("General");
  const { currentFolder, createdFolderId } = useAppSelector(folders);
  const { globalSearchItems, selectedSearchItems } = useAppSelector(dashboard);
  const { itemsToRestore } = useAppSelector(trashBin);
  const { getAction } = useActions();
  const [stateSigningType, setStateSigningType] = useState<
    SigningsType | undefined
  >(undefined);
  const isAllSameEntitiesSelected = globalSearchItems.every(
    (item, _, array) =>
      item.isFileDeleted === array[0].isFileDeleted &&
      item.isSigningEntity === array[0].isSigningEntity &&
      item.participantType === array[0].participantType &&
      item.status === array[0].status,
  );

  useEffect(() => {
    createdFolderId && dispatch(getFolder({ id: createdFolderId }));
  }, [createdFolderId, dispatch]);

  useEffect(() => {
    if (currentFolder) {
      dispatch(setSelectedSearchItems([]));
      dispatch(getFolder({ id: currentFolder }));
    }
  }, [dispatch, currentFolder, itemsToRestore]);

  const handleClearSelected = useCallback(() => {
    dispatch(setSelectedSearchItems([]));
  }, [dispatch]);

  useEffect(() => {
    return handleClearSelected;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toast.dismiss({ containerId: "Undo" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFolder]);

  const handleSelectAll = () => {
    if (!isAllSameEntitiesSelected) {
      toastInfo(t("toastSelectImpossible"));
      return;
    }
    dispatch(
      setSelectedSearchItems(
        selectedSearchItems.length < globalSearchItems?.length
          ? [...globalSearchItems]
          : [],
      ),
    );
  };

  const handleSelectItem = (item: IGlobalSearchDoc) => {
    const isSameEntitiesSelected = [...selectedSearchItems, item].every(
      (item, _, array) =>
        item.isFileDeleted === array[0].isFileDeleted &&
        item.isSigningEntity === array[0].isSigningEntity &&
        item.participantType === array[0].participantType &&
        item.status === array[0].status,
    );
    if (
      !isSameEntitiesSelected &&
      !selectedSearchItems.some((el) => el.id === item.id)
    ) {
      toastInfo(t("toastSelectImpossible"));
      return;
    }
    dispatch(
      setSelectedSearchItems(
        selectedSearchItems.some((el) => el.id === item.id)
          ? [...selectedSearchItems.filter((el) => el.id !== item.id)]
          : [...selectedSearchItems, item],
      ),
    );
  };

  const signingsType = selectedSearchItems[0]
    ? getSigningType({
        participantType: selectedSearchItems[0].participantType,
        status: selectedSearchItems[0].status,
      })
    : stateSigningType;

  const isViewHistory =
    selectedSearchItems[0]?.isFileDeleted &&
    selectedSearchItems[0]?.isSigningEntity &&
    !selectedSearchItems[0]?.previewUrl;

  return (
    <div className={styles.GlobalSearchTable}>
      <GlobalSearchModals signingsType={signingsType} />
      <h2 className={styles.title}>
        {globalSearchItems.length
          ? t("results", { count: globalSearchItems.length })
          : t("noResults")}
      </h2>

      <header
        className={cs([
          styles.tr,
          selectedSearchItems.length > 0 && styles.active,
        ])}
      >
        <Checkbox
          name={tG("all")}
          onChange={handleSelectAll}
          isChecked={
            selectedSearchItems.length > 0 &&
            selectedSearchItems.length === globalSearchItems.length
          }
          isFakeDisabled={!isAllSameEntitiesSelected}
        />
        {selectedSearchItems.length > 0 ? (
          <ul className={styles.actions}>
            {getSearchItems({
              selectedItems: selectedSearchItems,
              selectedItemsCount: selectedSearchItems.length,
              status: selectedSearchItems[0]?.status,
              isSigningEntity: selectedSearchItems[0]?.isSigningEntity,
              isFileDeleted: selectedSearchItems[0]?.isFileDeleted,
              type: selectedSearchItems[0]?.type,
              isAllShowed: true,
              isViewHistory,
              signingsType,
            })?.map((el, index) => (
              <li
                className={styles.action}
                key={index}
                onClick={getAction({
                  name: el,
                  signingsType,
                  signingsStatus: selectedSearchItems[0].status,
                  isTrash: selectedSearchItems[0].isFileDeleted,
                  isGlobalSearch: true,
                  isViewHistory,
                })}
              >
                <Icon name={el} size={18} />{" "}
                {getMenuTitle({
                  name: el,
                  signingsType,
                  signingsStatus: selectedSearchItems[0].status,
                  isViewHistory,
                })}
              </li>
            ))}
          </ul>
        ) : (
          <>
            <HeaderCell name={tG("name")} />
            <HeaderCell name={t("type")} />
            <HeaderCell name={t("attributes")} />
            <HeaderCell name={t("date")} />
          </>
        )}
      </header>

      {globalSearchItems?.map((item, index) => (
        <div
          key={item.id}
          style={
            index === globalSearchItems.length - 1
              ? { marginBottom: "5rem" }
              : {}
          }
        >
          <GlobalSearchTableRow
            onChange={() => handleSelectItem(item)}
            handleSetSigningType={setStateSigningType}
            item={item}
          />
        </div>
      ))}

      <NoTableData
        isSearch
        isNoFilteredData={globalSearchItems?.length === 0}
      />
    </div>
  );
};
