import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  useAppDispatch,
  useAppSelector,
  signings,
  folders,
  dashboard,
} from "store";
import {
  setIsDeleteInboxItemsModal,
  setIsInboxRejectRequestModal,
  setIsMovingItemsModal,
  setInboxToRejectRequest,
} from "store/signings";
import {
  setDestinationFolder,
  setCurrentContextFolder,
  setRenameModalItem,
} from "store/folders";
import { setIsViewHistoryModal } from "store/dashboard";

import {
  AreYouSureModal,
  MovingItemsModal,
  CreateFolderModal,
  RenameModal,
  RejectSigningModal,
  ActionsHistoryModal,
} from "components";
import { Modal } from "components/UI";
import { toastSuccess } from "utils";
import { useActions } from "hooks";
import { PATHES } from "constants/pathes";

import styles from "./styles.module.scss";

type SigningsInboxTableModalsProps = {
  refreshState: () => void;
};

export const SigningsInboxTableModals: FC<SigningsInboxTableModalsProps> = ({
  refreshState,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("Table", {
    keyPrefix: "SigningsInbox.Modals",
  });
  const {
    isDeleteInboxItemsModal,
    isInboxRejectRequestModal,
    isMovingItemsModal,
    selectedInboxItems,
    inboxDocs,
    inboxToRejectRequest,
  } = useAppSelector(signings);
  const { renameModalItem } = useAppSelector(folders);
  const { isViewHistoryModal } = useAppSelector(dashboard);
  const isSomeNeedToSign = selectedInboxItems.some(
    (el) => el.status === "NEED_TO_SIGN",
  );
  const docId = selectedInboxItems[0]?.id;
  const docName = selectedInboxItems[0]?.name;
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
  const { handleDeleteInboxSuccess } = useActions();

  const handleCloseAreYouSureMoveToBin = () => {
    dispatch(setIsDeleteInboxItemsModal(false));
  };

  const handleConfirmAreYouSureMoveToBin = async () => {
    await handleDeleteInboxSuccess(refreshState);
    handleCloseAreYouSureMoveToBin();
  };

  const handleCloseAreYouSureRejectRequest = () => {
    dispatch(setIsInboxRejectRequestModal(false));
  };

  const handleConfirmAreYouSureRejectRequest = async () => {
    handleCloseAreYouSureRejectRequest();
    refreshState();
    navigate(PATHES.INBOX_VOIDED);
    toastSuccess(
      inboxToRejectRequest.length > 1
        ? t("toastRejectRequestsSuccess")
        : t("toastRejectRequestSuccess"),
    );
    dispatch(setInboxToRejectRequest([]));
  };

  const handleCloseMoveModal = () => {
    dispatch(setIsMovingItemsModal(false));
    dispatch(setDestinationFolder(null));
    dispatch(setCurrentContextFolder(null));
  };

  const handleOpenCreate = () => {
    setIsCreateModal(true);
  };

  const handleCloseCreate = () => {
    setIsCreateModal(false);
    dispatch(setCurrentContextFolder(null));
  };

  const handleCloseRenameModal = () => {
    dispatch(setRenameModalItem(""));
    dispatch(setCurrentContextFolder(null));
  };

  return (
    <>
      <Modal
        isShowed={isDeleteInboxItemsModal}
        onClose={handleCloseAreYouSureMoveToBin}
      >
        <AreYouSureModal
          onClose={handleCloseAreYouSureMoveToBin}
          onSubmit={handleConfirmAreYouSureMoveToBin}
          info={t("areYouSureMoveToBin")}
          addInfo={
            isSomeNeedToSign ? (
              <p className={styles.addInfo}>
                {t("areYouSureMoveToBinNeedSign")}
              </p>
            ) : (
              <></>
            )
          }
          confirmButtonTitle={t("areYouSureMoveToBinConfirmButtonTitle")}
        />
      </Modal>
      <Modal
        isShowed={isInboxRejectRequestModal}
        onClose={handleCloseAreYouSureRejectRequest}
      >
        <RejectSigningModal
          onClose={handleCloseAreYouSureRejectRequest}
          onSubmit={handleConfirmAreYouSureRejectRequest}
        />
      </Modal>
      <Modal isShowed={isMovingItemsModal}>
        <MovingItemsModal
          onClose={handleCloseMoveModal}
          onCreate={handleOpenCreate}
          refreshState={refreshState}
          isInbox
        />
      </Modal>
      <Modal
        styleWrap={{ zIndex: 110 }}
        isShowed={isCreateModal}
        onClose={handleCloseCreate}
      >
        <CreateFolderModal onClose={handleCloseCreate} isMoving />
      </Modal>
      <Modal isShowed={!!renameModalItem}>
        <RenameModal
          onClose={handleCloseRenameModal}
          inboxOldName={inboxDocs.find((el) => el.id === renameModalItem)?.name}
          refreshState={refreshState}
        />
      </Modal>
      <Modal isShowed={!!isViewHistoryModal}>
        <ActionsHistoryModal
          onClose={() => dispatch(setIsViewHistoryModal(false))}
          docName={docName}
          id={docId}
        />
      </Modal>
    </>
  );
};
