export const getPasswordIcon = (
  password: string,
  isPasswordVisible: boolean,
) => {
  if (password && isPasswordVisible) {
    return "eye-opened";
  }
  if (password && !isPasswordVisible) {
    return "eye-closed";
  }
  return undefined;
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const toCapitalizedWords = (name: string) => {
  // Match words that start with an alphabet letter followed by zero or more lowercase letters
  const words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map((word) => capitalize(word)).join(" ");
};
